import React from "react";
import './Login.scss'

// COMPONENTS
import LoginForm from "../../components/LoginForm";
import PageContainer from "../../components/PageContainer";

const Login = () => {
    return (
        <PageContainer cssClass={'login'}>
            <section className={`login__container`}>
                <div className={`login__container--blend`}>
                    <div className={`login__form`}>
                        <div className={`app__logo--round`}>
                            <span className={`title`}>Filmliebe</span>
                            <span className={`label`}>Anmelden</span>
                        </div>
                        <LoginForm />
                    </div>
                </div>
            </section>
        </PageContainer>
    )
}

export default Login

import React, { useEffect, useState } from 'react'
import axios from 'axios';

import './OurMovies.scss'

// COMPONENTS
import PageContainer from '../../components/PageContainer';
import Teaser from '../../components/Teaser';
import Icon from '../../components/Icon';

const OurMovies = () => {

    const [movies, setMovies] = useState([]);
    const [listData, setListData] = useState([])
    const [page, setPage] = useState(2);

    const config = {
        headers: {
            'Authorization': `bearer ${process.env.REACT_APP_MOVIEDB_ACCESS_TOKEN}`,
            'Content-Type': 'application/json;charset=utf-8'
        }
    }

    const getMore = async () => {
        setPage(page + 1)
        await axios.get(`https://api.themoviedb.org/4/list/117563?page=${page}&api_key=${process.env.REACT_APP_MOVIEDB_KEY}&language=de-DE`, config)
            .then((res) => {
                setMovies([...movies, ...res.data.results]);
            }).catch(err => console.log(err));
    }

    useEffect(() => {
        axios.get(`https://api.themoviedb.org/4/list/117563?page=1&api_key=${process.env.REACT_APP_MOVIEDB_KEY}&language=de-DE`, config)
            .then((res) => {
                setListData(res.data);
            }).catch(err => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PageContainer cssClass={'our-list'}>

            <h1>Meine Filme</h1>
            <p>{listData.total_results} Filme</p>

            <section className={`list list__container`}>
                {listData?.results?.map((_listData) => <Teaser content={_listData} />)}
                {movies.map((_movies) => <Teaser content={_movies} />)}
            </section>

            {page <= listData.total_pages ? <button onClick={() => getMore()} className={`load-more`}>
                <span>zeige mehr</span>
                <Icon name={'chevron-down'} color={'#e60a3a'} /></button>
                : null}

        </PageContainer>
    )
}

export default OurMovies

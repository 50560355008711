import React from "react";

import "./PageContainer.scss"

// COMPONENTS
import Navbar from "./Navbar";
import { useAuth } from "../context/AuthContext";

const PageContainer = props => {

    const { cssClass, children } = props
    const {currentUser} = useAuth()

    return (
        <>
            {currentUser && <Navbar />}
            <div data-aos="fade-up" data-aos-once="true" className={`page page__${cssClass}`}>
                <div className={`page__inner`}>
                    {children}
                </div>
            </div>
        </>
    )
}

export default PageContainer

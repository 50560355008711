import React from "react"
import Sprite from '../assets/icons/_sprite.svg'

import './Icon.scss'

const Icon = ({ name, color, size = 32 }) => {
    return (
        <div className={`icon`}>
            <svg style={{ position: 'relative' }} width={size} height={size} viewBox="0 0 1000 500" color={color}>
                <use href={Sprite +'#'+ name} />
            </svg>
        </div>
    )
}

export default Icon
import React from 'react'
import './Rating.scss'

// COMPONENTS

const Rating = props => {

    const { number } = props

    return (
        <div className={`rating p${number * 10}`}>
            <span className={`primary`}>{number}</span>
            <span className={`label`}>RATING</span>
        </div>
    )
}

export default Rating

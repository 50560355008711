import React, { useContext, useEffect, useState } from "react"
import { auth } from "../firebase/firebase"

const AuthContext = React.createContext()

export const useAuth = () => {
    return useContext(AuthContext)
}

export const AuthProvider = ({children}) => {

    const [currentUser, setCurrentUser] = useState()

    const signUp = (email, password) => {
        return auth.createUserWithEmailAndPassword(email, password)
    }

    const signIn = (email, password) => {
        return auth.signInWithEmailAndPassword(email, password)
    }

    const recoverPassword = (email) => {
        return auth.sendPasswordResetEmail(email)
    }

    const logOut = () => {
        return auth.signOut()
    }

    const value = {
        currentUser,
        signIn,
        signUp,
        recoverPassword,
        logOut
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
        })

        return unsubscribe
    }, [])

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}
    
import React, { useState } from 'react'
import "./Search.scss"

// COMPPONENTS
import PageContainer from '../../components/PageContainer'
import Teaser from '../../components/Teaser'

// CONTEXT
import { useSearch } from '../../context/SearchContext'

const Search = () => {

  const { setSearchString, searchResult } = useSearch()
  const [searchValue, setSearchValue] = useState('')

  const searchHandler = (e) => {
    setSearchValue(e.target.value)
  }

  const keyPressHandler = (e) => {
    if (e.key === 'Enter') {
      setSearchString(searchValue)
    }
  }

  return (
    <PageContainer cssClass={'search'}>
      <input
        type="text"
        className={`input__search`}
        onChange={searchHandler}
        onKeyPress={keyPressHandler}
        autoFocus
        placeholder={`Suche`} />

      <section className={`list list__container`}>
        {searchResult.length !== 0 ?
          searchResult.map((result, index) => <Teaser key={result.id} content={result} />)
          : null}
      </section>
    </PageContainer>
  )
}

export default Search

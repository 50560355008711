import React from 'react'
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import AOS from 'aos';
import '../node_modules/aos/src/sass/aos.scss';

// COMPONENTS
import PrivateRoute from './pages/PrivateRoute';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import RecoverPassword from './pages/RecoverPassword';
import Page404 from './pages/404';
import Search from './pages/Search';
import Movie from './pages/Movie';
import Tv from './pages/Tv';
import OurMovies from './pages/OurMovies'

// CONTEXT
import { AuthProvider } from './context/AuthContext';
import { SearchProvider } from './context/SearchContext';

const App = () => {
  AOS.init()
  return (
    <Router>
      <AuthProvider>
        <SearchProvider>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/search" element={<Search />} />
              <Route exact path="/search/:id" element={<Movie />} />
              <Route exact path="/tv/:id" element={<Tv />} />
              <Route exact path="/our-movies" element={<OurMovies />} />
            </Route>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/recover-password" element={<RecoverPassword />} />
            <Route path="/:404" element={<Page404 />} />
          </Routes>
        </SearchProvider>
      </AuthProvider>
    </Router>
  )
}

export default App;

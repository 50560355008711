import React, { useContext, useEffect, useState } from "react"
import axios from "axios"

const SearchContext = React.createContext()

export const useSearch = () => {
  return useContext(SearchContext)
}

export const SearchProvider = ({ children }) => {

  const [searchResult, setSearchResult] = useState([])
  const [searchString, setSearchString] = useState('')

  const [movie, setMovie] = useState({
    details: {},
    credits: [],
    trailer: {},
    recommendations: {},
    isOnMyList: {}
  })

  // Request methods
  const getMovies = (value) => {
    let requestURL = ''
    if (searchString === '') {
      requestURL = `https://api.themoviedb.org/3/movie/top_rated?api_key=${process.env.REACT_APP_MOVIEDB_KEY}&language=de-DE`
    } else {
      requestURL = `https://api.themoviedb.org/3/search/multi?api_key=${process.env.REACT_APP_MOVIEDB_KEY}&query=${value}&language=de-DE&page=1&include_adult=false`
    }

    axios.get(requestURL)
      .then(response => {
        setSearchResult(response.data.results)
      })
      .catch(err => console.log(err));
  }

  // get everything which is related to one movie item
  // and set it to the global context store.
  const getMovieData = (movieID) => {
    return axios.all([
      axios.get(`https://api.themoviedb.org/3/movie/${movieID}?api_key=${process.env.REACT_APP_MOVIEDB_KEY}&language=de-DE`),
      axios.get(`https://api.themoviedb.org/3/movie/${movieID}/credits?api_key=${process.env.REACT_APP_MOVIEDB_KEY}`),
      axios.get(`https://api.themoviedb.org/3/movie/${movieID}/videos?api_key=${process.env.REACT_APP_MOVIEDB_KEY}`),
      axios.get(`https://api.themoviedb.org/3/movie/${movieID}/recommendations?api_key=${process.env.REACT_APP_MOVIEDB_KEY}&language=de-DE`),
    ])
      .then(axios.spread((movieDetail, movieCredits, movieTrailer, movieRecommendations) => {

        setMovie({
          details: movieDetail.data,
          credits: movieCredits.data.cast,
          trailer: movieTrailer.data.results[0],
          recommendations: movieRecommendations.data.results
        })
      })).catch(err => console.log(err));
  }

  const getSeriesData = (seriesID) => {
    return axios.all([
      axios.get(`https://api.themoviedb.org/3/tv/${seriesID}?api_key=${process.env.REACT_APP_MOVIEDB_KEY}&language=de-DE`),
      axios.get(`https://api.themoviedb.org/3/tv/${seriesID}/credits?api_key=${process.env.REACT_APP_MOVIEDB_KEY}`),
      axios.get(`https://api.themoviedb.org/3/tv/${seriesID}/videos?api_key=${process.env.REACT_APP_MOVIEDB_KEY}`),
      axios.get(`https://api.themoviedb.org/3/tv/${seriesID}/recommendations?api_key=${process.env.REACT_APP_MOVIEDB_KEY}&language=de-DE`),
    ])
      .then(axios.spread((movieDetail, movieCredits, movieTrailer, movieRecommendations) => {

        setMovie({
          details: movieDetail.data,
          credits: movieCredits.data.cast,
          trailer: movieTrailer.data.results[0],
          recommendations: movieRecommendations.data.results
        })
      })).catch(err => console.log(err));
  }

  const value = {
    searchResult,
    setSearchString,
    getMovieData,
    getSeriesData,
    setMovie,
    movie
  }

  useEffect(() => {
    getMovies(searchString)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString])

  return (
    <SearchContext.Provider value={value}>
      {children}
    </SearchContext.Provider>
  )
}
/*
    * @readme before start doing something !!!! 
    * Please keep in mind that empty request to the list ID results in a complete purge and deletes every item from the list.+
    * You need to create a test list on themoviedb.org when you will test new post request or implement new functionality to this component+!!!
*/

import React, { useEffect, useState } from 'react'
import axios from 'axios'

import "./isOnMyList.scss"

// COMPONENTS
import Icon from './Icon'

const IsOnMyList = props => {

    const [onMyList, setOnMyList] = useState(false)
    const { movieId, mediaType, onImage = false, size, shadow = false } = props

    // Check if this movie is on my list
    const onMyListHandler = (_id, _type) => {
        const config = {
            headers: {
                'Authorization': `bearer ${process.env.REACT_APP_MOVIEDB_ACCESS_TOKEN}`,
                'Content-Type': 'application/json;charset=utf-8'
            }
        };
        axios.get(`https://api.themoviedb.org/4/list/117563/item_status?media_id=${_id}&media_type=${_type}`, config)
            .then(res => {
                console.log(res)
                setOnMyList(res.data.success)
            })
            .catch(err => console.log(err))
    }

    // Method to add a movie to my List
    const addHandler = () => {
        //console.log('addHandler')
        let list_id = '117563';
        let api_host = 'https://api.themoviedb.org';

        var config = {
            headers: {
                'Authorization': `bearer ${process.env.REACT_APP_MOVIEDB_ACCESS_TOKEN}`,
                'Content-Type': 'application/json;charset=utf-8'
            }
        };

        var bodyParameters = {
            "items": [{
                "media_type": mediaType,
                "media_id": movieId
            }]
        }

        axios.post(
            api_host + '/4/list/' + list_id + '/items',
            bodyParameters,
            config
        ).then((response) => {
            //console.log(response)
        }).catch((error) => {
            console.log(error)
        });
    }

    // Removes a movie from my list
    const removeHandler = () => {
        //console.log('removeHandler')
        let list_id = '117563';
        let api_host = 'https://api.themoviedb.org';

        axios.delete(
            api_host + '/4/list/' + list_id + '/items',
            {
                headers: {
                    "Authorization": `bearer ${process.env.REACT_APP_MOVIEDB_ACCESS_TOKEN}`,
                    "Content-Type": "application/json;charset=utf-8"
                },
                data: {
                    "items": [
                        {
                            "media_type": mediaType,
                            "media_id": movieId
                        }
                    ]
                }
            }

        ).then((response) => {
            //console.log(response)
        }).catch((error) => {
            console.log(error)
        });

    }

    // onClick handler to switch between add and remove
    const removeAddHandler = (_id) => {
        console.log('removeAddHandler: onMyList: ', onMyList)
        switch (onMyList) {
            case false:
                addHandler()
                setOnMyList(true)
                break;
            case true:
                removeHandler()
                setOnMyList(false)
                break;
            default:
        }
    }

    useEffect(() => {
        onMyListHandler(movieId, mediaType)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [movieId])

    return (
        <div
            onClick={() => removeAddHandler(movieId)}
            className={`${!onMyList ? 'inactive' : 'active'} heart__circle--${size} ${onImage ? 'on-img' : ''} ${shadow ? 'shadow' : ''}`}>
            <Icon name={'heart-fill'} color={onMyList ? '#e60a3a' : '#dddddd'} />
        </div>
    )
}

export default IsOnMyList

import React from "react";

// COMPONENTS
import PageContainer from "../../components/PageContainer";

const Dashboard = () => {

    return (
        <PageContainer cssClass={'dashboard'}>
            <h1>Dashboard</h1>
            <p>Durchschnittliches Rating:</p>
            <p>Gesamte Anzahl:</p>
            <p>Zuletzt hinzugefügt:</p>
            <p>Aktuell im Kino:</p>
        </PageContainer>
    )
}

export default Dashboard

import React from 'react'
import Navbar from '../components/Navbar'
import PageContainer from '../components/PageContainer'

const Page404 = () => {
  return (
    <PageContainer cssClass={'404'}>
        <Navbar />
        <h1>404</h1>
        <p>The requested page does not exist.</p>
    </PageContainer>
  )
}

export default Page404

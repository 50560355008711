import React, { useState } from 'react'
import './UserNav.scss'

// ICONS
import Icon from './Icon'

// CONTEXT
import { useAuth } from '../context/AuthContext'

const UserNav = () => {

    const { logOut, currentUser } = useAuth();
    const [toggleMenu, setToggleMenu] = useState(false);

    return (
        <ul className={`navbar__user-nav`}>
            <li>
                <button onClick={() => setToggleMenu(!toggleMenu)}>
                    {currentUser.email.length > 12 ?
                        currentUser.email.substring(0, 12) + '...'
                        : currentUser.email}

                    <Icon name="person-circle" color="#ccc" />

                </button>
                <ul className={`user-nav__subnav ${toggleMenu ? 'active' : 'inactive'}`}>
                    <li><button onClick={logOut}>Abmelden</button></li>
                </ul>
            </li>
        </ul>
    )
}

export default UserNav

import {useRef, useEffect} from "react";

// Helper
export const dateFormatHelper = (dateString) => {
    // split the origin date String in an array
    let dateParts = dateString.split('-');
    // reorder the date parts to a new format
    let newDateFormat = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

    return newDateFormat.toLocaleDateString('de-DE', {
        year: 'numeric', 
        month: 'numeric', 
        day: '2-digit' 
    })
  }

  export const usePrevious = (value) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }
import React, { useRef, useState } from 'react'
import { useAuth } from '../context/AuthContext'
import { Link, useNavigate, useLocation } from 'react-router-dom';

import './LoginForm.scss';

// COMPONENTS
import Alert from './Alert';

const LoginForm = () => {

    const emailRef = useRef()
    const passwordRef = useRef()

    const { currentUser, signIn } = useAuth()

    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || "/"

    const [error, setError] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            setError('')
            await signIn(emailRef.current.value, passwordRef.current.value)
            navigate(from, {replace: true})
        } catch {
            setError('Error Catch')
        }
    }

    return (
        <div className={`form__container`}>

            {error && <Alert message={'Deine E-Mail adresse oder Passwort stimmt nicht'} />}
            {currentUser && currentUser.email}

            <form onSubmit={handleSubmit}>
                <input type={`email`} name={`email`} ref={emailRef} placeholder={`E-Mail`} />
                <input type={`password`} name={`password`} ref={passwordRef} placeholder={`Password`} />
                <button className={`button__signUp`}>Anmelden</button>
                <caption className={`form__caption`}>
                    <Link to={'/recover-password'}>Passwort vergessen ?</Link>
                </caption>
            </form>
        </div>
    )
}

export default LoginForm
import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { dateFormatHelper } from '../../utils'
import './Tv.scss'

// COMPONENTS
import PageContainer from '../../components/PageContainer'
import Icon from '../../components/Icon'
import SectionWithHeadline from '../../components/SectionWithHeadline'
import Rating from '../../components/Rating'
import NumberFormat from 'react-number-format'
import IsOnMyList from '../../components/IsOnMyList'
import Teaser from '../../components/Teaser'

// CONTEXT
import { useSearch } from '../../context/SearchContext'
import PersonTeaser from '../../components/Teaser/PersonTeaser'

const Tv = () => {

    const urlParameter = useParams()
    const { getSeriesData, movie } = useSearch()

    const { details, credits, recommendations, trailer } = movie

    useEffect(() => {
        getSeriesData(urlParameter.id)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParameter.id])

    const backdropStyle = {
        backgroundImage: `url("https://image.tmdb.org/t/p/w1280/${movie.details.backdrop_path}")`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover'
    }

    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || "/search"

    if (Object.keys(movie.details).length === 0) {
        return (<h1>Loading</h1>)
    } else {
        return (
            <PageContainer cssClass={`movie`}>
                <header style={backdropStyle} className="movie__header">

                    <div className={`header__left`}>
                        <button className={`btn__back`} onClick={() => navigate(from, { replace: true })}>
                            <Icon name={'arrow-left-circle'} size={64} />
                            <div className={`back__title`}>
                                <span className={`title`}>{movie.details.name}</span>
                                <span className={`back`}>Zurück</span>
                            </div>
                        </button>

                        <div className={`movie__short-description`}>
                            <div className={`movie__title--big`}>
                                <h1>{details.name}</h1>
                                <h2>{details.tagline}</h2>
                            </div>
                            <div className={`movie__description`}>
                                <h3 className={`headline--sm`}>Beschreibung</h3>
                                <p>{details.overview.length > 256 ? details.overview.slice(0, 256) + '...' : details.overview}</p>
                            </div>
                        </div>
                    </div>

                    <div className={`header__right`}>
                        <figure className={`movie__detail-poster`}>
                            <img alt={details.original_title} src={`https://image.tmdb.org/t/p/w300_and_h450_bestv2/${details.poster_path}`} />
                        </figure>
                    </div>

                    <section className={`header__bottom`}>
                        <div className={`header__rating`}>
                            <Rating number={details.vote_average} />
                        </div>

                        <div className={`info__container`}>
                            <ul className={`short-info`}>
                                <li className={`text__icon`}>
                                    <Icon name={'clock-history'} />
                                    {details.runtime} min.
                                </li>
                                <li className={`text__icon`}>
                                    <Icon name={'calendar3'} />
                                    {details.release_date && dateFormatHelper(details.release_date)}
                                </li>
                                <li className={`text__icon`}>
                                    <a className={`text__icon`} href={details.homepage} target="_blank" rel="noopener noreferrer">
                                        <Icon name={'link-45deg'} /> www
                                    </a>
                                </li>
                                <li className={`text__icon`}>
                                    <Icon name={'download'} />
                                    <NumberFormat value={details.budget} suffix={' $'} displayType={'text'} thousandSeparator={true} />
                                </li>
                                <li className={`text__icon`}>
                                    <Icon name={'upload'} />
                                    <NumberFormat value={details.revenue} suffix={' $'} displayType={'text'} thousandSeparator={true} />
                                </li>
                                <li className={`text__icon`}>
                                    {details.budget * 1.5 < details.revenue ?
                                        <div className={`text__icon`}><Icon name={'arrow-up-right-circle'} color={'#00cc00'} /> Erfolgreich</div> :
                                        <div className={`text__icon`}><Icon name={'arrow-down-right-circle'} color={'#cc0000'} />Flopp</div>
                                    }
                                </li>
                            </ul>
                            <div className={`btn__group`}>
                                <a className="btn btn__ghost" href={`https://www.youtube.com/embed/${trailer.key}`} rel="noopener noreferrer" target="_blank">
                                    <Icon name={'play-fill'} color={''} size={48} />
                                </a>
                                <IsOnMyList movieId={details.id} size={'default'} mediaType={'tv'} shadow={false} />
                                <div className={`btn__circle`}>
                                    <a className="btn btn__ghost--imdb" href={`https://www.imdb.com/title/${details.imdb_id}`} rel="noopener noreferrer" target="_blank">
                                        IMDb
                                    </a>
                                </div>
                            </div>
                        </div>

                    </section>
                </header>
                <main className={`main main__content`}>
                    <SectionWithHeadline headline={details.overview && 'BESCHREIBUNG'}>
                        <p>
                            {details.overview}
                        </p>
                    </SectionWithHeadline>
                    <SectionWithHeadline headline={'CAST'}>
                        <div className={`section__content-teaser-grid`}>
                            {credits.slice(0, 8).map(_credits => <PersonTeaser key={_credits.id} content={_credits} size={'small'} />)}
                        </div>
                    </SectionWithHeadline>
                    <SectionWithHeadline headline={'VERWANDTE TITEL'}>
                        <div className={`section__content-teaser-grid`}>
                            {recommendations.map(_recommendations => <Teaser key={_recommendations.id} content={_recommendations} />)}
                        </div>
                    </SectionWithHeadline>
                </main>
            </PageContainer>
        )
    }

}

export default Tv

import React from 'react'

// COMPONENTS
import MovieTeaser from './MovieTeaser';
import TvTeaser from './TvTeaser';
import PersonTeaser from './PersonTeaser';

const Teaser = props => {

    const { content, size = 'default' } = props;

    // media_type can be: movie, tv, person

    switch (content.media_type) {
        case 'tv':
            return <TvTeaser key={content.id} content={content} size={size} />
        case 'person':
            return <PersonTeaser key={content.id} content={content} size={size} />
        default:
            return <MovieTeaser key={content.id} content={content} size={size} />
    }
}

export default Teaser

import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";


// CONTEXT
import { useAuth } from "../context/AuthContext";

const PrivateRoute = () => {

    const { currentUser } = useAuth()
    const location = useLocation()


    // checks if the state of the currentUser is set properly
    // @todo return a loading spinner
    if (currentUser === undefined) return null;

    return currentUser ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
}

export default PrivateRoute

import React from 'react'
import './SectionWithHeadline.scss'

const SectionWithHeadline = props => {

    const { headline, scrollable = false, children } = props

    return (
        <section className={`section ${scrollable ? 'scrollable' : ''}`}>
            <h4 className={`section__headline`}>{headline}</h4>
            <div className={`section__content`}>
                {children}
            </div>
        </section>
    )
}

export default SectionWithHeadline

import React from 'react'
import { Link } from 'react-router-dom'

import './MovieTeaser.scss'

// COMPONENTS
import IsOnMyList from '../IsOnMyList.js'

const MovieTeaser = props => {
    const { content } = props

    return (
        <article data-aos="fade-in" data-aos-once="true" className={`movie-teaser`}>
            {/* <Icon name={'film'} color={''} size={'24'} /> */}
            <figure>
                <Link to={`/search/${content.id}`}>
                    {content.poster_path !== null ?
                        <img alt={content.title} src={`https://image.tmdb.org/t/p/w185_and_h278_bestv2/${content.poster_path}`} />
                        : <img alt={'fallback'} src={require(`../../assets/images/default_cover.jpg`)} />
                    }
                </Link>
                <figcaption>
                    FILM
                    <IsOnMyList movieId={content.id} mediaType={'movie'} size={'small'} shadow={true} onImage={false} />
                </figcaption>
            </figure>
        </article>
    )
}

export default MovieTeaser

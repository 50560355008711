import React from "react";
import "./Alert.scss"

const Alert = (props) => {
    const {message} = props;
    return (
        <div className={`notification notification__alert`}>
            {message}
        </div>
    )
}

export default Alert
import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import './Navbar.scss'

// COMPONENTS
import UserNav from './UserNav'
import Icon from './Icon'

const Navbar = () => {
  return (
    <header className={`page-container__header`}>
      <div className={`inner header__inner`}>

        <div className={`app__logo`}>
          <Link to="/">
            <span>film</span>
            <span>liebe</span>
          </Link>
        </div>

        <nav className={`page-container__navbar`}>
          <ul className={`navbar`}>
            <li className={`navbar__item`}>
              <NavLink to="/search" activeClassName="selected">
                <Icon name={'search'} color={'#E2CA2C'} />
              </NavLink>
            </li>
            <li className={`navbar__item`}>
              <NavLink to="/our-movies" activeClassName="selected">
                <Icon name={'heart-fill'} color={'#e60a3a'} />
              </NavLink>
            </li>
            <li className={`navbar__item`}>
              <NavLink to="/" activeClassName="selected">
                <Icon name={'layout-sidebar-reverse'} color={'#25E3CA'} />
              </NavLink>
            </li>
          </ul>
          <UserNav />
        </nav>
      </div>
    </header>
  )
}

export default Navbar

import React from 'react'

import './MovieTeaser.scss'
import './PersonTeaser.scss'

// COMPONENTS

const PersonTeaser = props => {
    const { content, size } = props

    return (
        <article data-aos="fade-in" data-aos-once="true" className={`person-teaser size-${size}`}>
            {/* <Icon name={'person-badge'} color={''} size={'24'} /> */}
            <figure>
                {content.profile_path !== null ?
                    <img alt={content.title} src={`https://image.tmdb.org/t/p/w185_and_h278_bestv2/${content.profile_path}`} />
                    : <img alt={'fallback'} src={require(`../../assets/images/default_cover.jpg`)} />
                }
                <figcaption>
                    <span className={`actor__name`}>{content.name}</span>
                    <span className={`actor__character`}>{content.character}</span>
                </figcaption>
            </figure>
        </article>
    )
}

export default PersonTeaser
